import './Contact.scss';
import {useDocumentTitle} from "../../utils/useDocumentTItle";
export const Contact = () => {

    useDocumentTitle("Kontakt - Katalog części KUBOTA")

    return (
        <div className={"contact-page"}>
            <h1>Kontakt</h1>
            <div className="wobis-row wobis-col3">
                <div className="wobis-col contact-card card">
                    <div className="wobis-contact-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.5313 20.217C12.2319 20.0798 11.9325 19.9425 11.6331 19.7804C10.1662 18.9521 8.81891 17.928 7.62828 16.7363C6.08451 15.2833 4.81646 13.5631 3.88548 11.6585C3.38667 10.6355 3.04995 9.5412 2.8874 8.41473C2.66606 7.27695 2.79212 6.0989 3.24921 5.03373C3.53091 4.57607 3.87057 4.15674 4.25977 3.78613C4.41753 3.60943 4.60935 3.4664 4.82373 3.36565C5.03811 3.26489 5.27064 3.20846 5.50736 3.19977C6.00012 3.27351 6.44626 3.53227 6.75496 3.92337C7.42867 4.67193 8.15227 5.34563 8.8634 6.05676C9.14341 6.29698 9.31808 6.63739 9.34997 7.00493C9.33797 7.31419 9.21889 7.60967 9.01312 7.84083C8.77607 8.14025 8.48913 8.41472 8.21465 8.70167C8.049 8.86116 7.92232 9.05668 7.84442 9.27304C7.76654 9.48934 7.73954 9.72075 7.76552 9.94924C7.9369 10.4843 8.23179 10.9715 8.62635 11.3716C9.10044 12.0203 9.57453 12.6191 10.111 13.2554C11.0749 14.3684 12.2175 15.3128 13.4919 16.05C13.6681 16.1831 13.8764 16.2671 14.0956 16.2935C14.3148 16.3197 14.537 16.2874 14.7396 16.1997C15.1632 15.9627 15.5397 15.6498 15.8499 15.2766C16.1183 14.9496 16.5029 14.7394 16.9229 14.6901C17.2966 14.7091 17.6489 14.8698 17.9085 15.1393C18.2453 15.4262 18.5323 15.7631 18.8441 16.075C19.1561 16.3868 19.4056 16.6115 19.6676 16.8984C19.9813 17.1747 20.2772 17.4705 20.5533 17.7842C20.7675 18.0612 20.87 18.4085 20.8403 18.7573C20.7292 19.1744 20.489 19.5455 20.1542 19.8177C19.6833 20.3076 19.111 20.6887 18.4774 20.9344C17.8439 21.18 17.1643 21.2844 16.4862 21.2401C15.1134 21.163 13.7693 20.8154 12.5313 20.217Z"
                                strokeMiterlimit="10" strokeLinecap="round"/>
                            <path
                                d="M17.7513 11.198C17.7513 9.85628 17.2184 8.56948 16.2696 7.62073C15.3209 6.67198 14.0341 6.13898 12.6923 6.13898"
                                strokeMiterlimit="10" strokeLinecap="round"/>
                            <path
                                d="M21.2189 10.8444C21.2189 8.69765 20.3661 6.6388 18.8481 5.1208C17.3301 3.6028 15.2712 2.75 13.1245 2.75"
                                strokeMiterlimit="10" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="wobis-contact-header">
                        <h2>Telefon</h2>
                    </div>
                    <div className="wobis-contact-description">
                        <div>Adam Żymła: Kom: <a href={"tel:+48609893929"}>+48 609 893 929</a></div>
                        <div>Damian Kwiotek: Kom: <a href={"tel:+48693325371"}>+48 693 325 371</a></div>
                    </div>
                </div>
                <div className="wobis-col contact-card card">
                    <div className="wobis-contact-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.9375 3.90622H5.0625C3.78534 3.90622 2.75 4.94156 2.75 6.21872V17.7812C2.75 19.0584 3.78534 20.0938 5.0625 20.0938H18.9375C20.2147 20.0938 21.25 19.0584 21.25 17.7812V6.21872C21.25 4.94156 20.2147 3.90622 18.9375 3.90622Z"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M2.95814 6.21872L12 12L21.146 6.32278"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="wobis-contact-header">
                        <h2>E-Mail</h2>
                    </div>
                    <div className="wobis-contact-description">
                        <a href={"mailto:zamowienia@wobis.pl"}>zamowienia@wobis.pl</a>
                        <a href={"mailto:adamzymla@wobis.pl"}>adamzymla@wobis.pl</a>
                        <a href={"mailto:damiankwiotek@wobis.pl"}>damiankwiotek@wobis.pl</a>
                    </div>
                </div>
                <div className="wobis-col contact-card card">
                    <div className="wobis-contact-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 12.7998C13.8502 12.7998 15.3499 11.3001 15.3499 9.4499C15.3499 7.59978 13.8502 6.09995 12 6.09995C10.1498 6.09995 8.65004 7.59978 8.65004 9.4499C8.65004 11.3001 10.1498 12.7998 12 12.7998Z" strokeMiterlimit="10" strokeLinecap="round"/>
                            <path d="M12 2.75C5.3001 2.75 4.18345 8.33325 5.3001 12.5654C6.28275 16.2726 9.23071 18.8074 11.1737 20.8844C11.2783 20.9995 11.4059 21.0915 11.5482 21.1545C11.6905 21.2175 11.8444 21.25 12 21.25C12.1556 21.25 12.3095 21.2175 12.4518 21.1545C12.594 21.0915 12.7217 20.9995 12.8263 20.8844C14.7693 18.8074 17.7172 16.2726 18.6999 12.5654C19.8165 8.33325 18.6999 2.75 12 2.75Z" strokeMiterlimit="10" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="wobis-contact-header">
                        <h2>Lokalizacja</h2>
                    </div>
                    <div className="wobis-contact-description">
                        ul. Bytomska 135,
                        41-803, Zabrze
                    </div>
                </div>
            </div>
        </div>
    );

};