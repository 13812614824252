import React from "react";
import {ClipLoader} from "react-spinners";

interface LoaderProps {
    loadingText?: string; // '?' oznacza, że prop jest opcjonalny
}

const Loader: React.FC<LoaderProps> = ({loadingText = "Wczytywanie danych..."}) => {
  return (
      <div className={"loading"}>
          <ClipLoader
              color={"#ff6400"}
              size={100}
          />
          <p>{loadingText}</p>
      </div>
  );
}

export default Loader;