import React, {useContext} from "react";
import MachineContext from "../../utils/MachineContext";
import {NavLink, useParams} from "react-router-dom";
import "./MachineComponent.scss";

export const MachineComponent: React.FC = () => {

    const {machine} = useContext(MachineContext);
    const params = useParams();
    const componentId = params.componentId as string;

    const matchedFeature = machine?.features?.find(feature => feature.id === Number.parseInt(componentId));

    if (!matchedFeature) {
        return <div>Component not found</div>;
    }

    return (
        <div>
            <h1 className={"machine-h1"}>{machine?.modelName + ": " + matchedFeature.featureName}</h1>
            <div className="machine-components wobis-row wobis-col4">
                {matchedFeature.figToFeatures?.map((figToFeature, index) => (
                    <div key={index} className={"machine-component-card card"}>
                        <div className="machine-component-img">
                            <NavLink to={`/maszyna/${machine?.id}/komponent/${matchedFeature.id}/czesci-komponentu/${figToFeature.id}`}>
                                <img className={"machine-comp-img"} src={figToFeature?.illustrations?.at(0)?.url} alt=""/>
                            </NavLink>
                        </div>
                        <div className="machine-component-title">
                            <NavLink to={`/maszyna/${machine?.id}/komponent/${matchedFeature.id}/czesci-komponentu/${figToFeature.id}`}>
                                {figToFeature.name + " (" + figToFeature.figNumber + ")"}
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};