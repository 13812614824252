import React, {useContext, useEffect} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import useFetch from "../../utils/useFetch";
import {Machine} from "../../models/Machine";
import MachineContext from "../../utils/MachineContext";
import "./MachinePage.scss";
import Loader from "../../components/loader/loader";
import {useDocumentTitle} from "../../utils/useDocumentTItle";

export const MachinePage: React.FC = () => {

    const {setMachine} = useContext(MachineContext);

    const params = useParams();
    const id = params.id as string;
    const url =  process.env.REACT_APP_API_URL + `/api/v1/machines/${id}`;

    const {
        data: machine,
        loading,
        error
    } = useFetch<Machine>(url);

    useEffect(() => {
        if (machine) setMachine(machine);
    }, [machine, setMachine]);

    let machineType = machine?.machineType == "MINIEXCAVATOR"? "Minikoparka " : "Ładowarka kołowa ";

    useDocumentTitle( machineType + machine?.modelName + " części zamienne - WOBIS" || "Wobis katalog części zamiennych Kubota");

    if (loading) return <Loader/>;
    if (error) return <div>Nie udało się wczytać danych</div>

    if (!machine) return <div>No data found</div>;

    return machine ? (
        <Outlet/>
    ) : (loading ? <Loader/> : <div>Nie udało się wczytać danych</div>);
};