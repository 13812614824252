import React from 'react';
import './HomePage.scss';
import useFetch from '../../utils/useFetch';
import {Machine} from "../../models/Machine";
import {PageableResponse} from "../../models/PageableResponse";
import {Link, useParams} from "react-router-dom";
import Loader from "../../components/loader/loader";
import {useDocumentTitle} from "../../utils/useDocumentTItle";

export const HomePage = () => {

    useDocumentTitle("WOBIS Katalog części zamiennych Kubota");

    const params = useParams();
    const type = params.type as string;
    const url = type ? process.env.REACT_APP_API_URL + `/api/v1/machines?type=${type.toUpperCase()}&sort=modelName,weight` : process.env.REACT_APP_API_URL + '/api/v1/machines?sort=modelName,weight';

    const {
        data: dataMachines,
        loading: loadingMachines,
        error: errorExcavators
    } = useFetch<PageableResponse<Machine>>(url);

    if (loadingMachines) {
        return <Loader/>;
    }

    if (errorExcavators || dataMachines === null) {
        return (
            <div className="home-page">
                <p>Nie udało się załadować danych...</p>
            </div>
        );
    }

    return (
        <div className="home-page">
            <h1 id={"home-page-h1"}>WOBIS Katalog części zamiennych Kubota</h1>
            <div className={"search-count"}>Znaleziono: {dataMachines.totalElements} elementów</div>
            <div className={"wobis-main-table-wrapper"}>
                <table className={"search-table"}>
                    <thead>
                    <tr>
                        <th>Model</th>
                        <th>Kategoria</th>
                        <th>Waga</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dataMachines.content.map((machine) => (
                        <tr key={machine.id} className={"machine-row"}>
                            <td className={"machine-name-cell"}><Link to={`/maszyna/${machine.id}`}>{machine.modelName}</Link></td>
                            <td>{machine.machineType === "MINIEXCAVATOR" ? "Minikoparka" : "Ładowarka Kołowa"}</td>
                            <td>{machine.weight + " " + machine.weightUnit}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};