import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import WebFont from 'webfontloader';
import {ListProvider} from "./utils/ListContext";

WebFont.load({
    google: {
        families: ['Roboto:400,700', 'sans-serif']
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ListProvider>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </ListProvider>
);

reportWebVitals();
