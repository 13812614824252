import React, {useContext, useEffect} from "react";
import MachineContext from "../../utils/MachineContext";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import "./MachineOverview.scss";

export const MachineOverview: React.FC = () => {

    const {machine} = useContext(MachineContext);

    return (
        <div className="machine-overview-page">
            <h1 className={"machine-h1"}>{machine?.machineType === "MINIEXCAVATOR" ? "Minikoparka" : "Ładowarka Kołowa"} {machine?.modelName}</h1>
            <div className="basic-machine-information">
                <span>Model: {machine?.modelName}</span>
                <span>Book Code: {machine?.bookCode}</span>
            </div>
            <div className="machine-overview">
                <div className="machine-overview-img">
                    <TransformWrapper
                        initialScale={1}
                        maxScale={3}
                        minScale={1}
                        wheel={{step: 0.2}}
                        centerOnInit={true}
                        doubleClick={{mode: "reset"}}
                    >
                        {({zoomIn, zoomOut, resetTransform, centerView, ...rest}) => (
                            <React.Fragment>
                                <div className="tools">
                                    <button onClick={() => zoomIn()} className={"zoom-in"}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.2438 17.7377C14.3825 17.7377 17.7377 14.3825 17.7377 10.2438C17.7377 6.10512 14.3825 2.75 10.2438 2.75C6.10512 2.75 2.75 6.10512 2.75 10.2438C2.75 14.3825 6.10512 17.7377 10.2438 17.7377Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.5621 15.5621L21.25 21.25" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M10.2439 6.24466V14.2431" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.243 10.2439H6.24463" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>Przybliż</span>
                                    </button>
                                    <button onClick={() => zoomOut()} className={"zoom-out"}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.2438 17.7377C14.3826 17.7377 17.7377 14.3825 17.7377 10.2438C17.7377 6.10511 14.3826 2.75 10.2438 2.75C6.10513 2.75 2.75002 6.10511 2.75002 10.2438C2.75002 14.3825 6.10513 17.7377 10.2438 17.7377Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M15.5621 15.5621L21.25 21.25" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.2429 10.2439H6.24452" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>Oddal</span>
                                    </button>
                                    <button onClick={() => centerView()} className={"zoom-center"}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 4.5V19.5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M19.5 12H4.5" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>Wyśrodkuj</span>
                                    </button>
                                    <button onClick={() => resetTransform()} className={"zoom-reset"}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.39462 7.70537C7.12924 6.96718 8.00279 6.38183 8.96495 5.98308C9.927 5.58435 10.9586 5.38013 12 5.38222C14.1028 5.38222 16.1195 6.21757 17.6064 7.70446C19.0933 9.19136 19.9286 11.208 19.9286 13.3107C19.9286 15.4146 19.0936 17.4323 17.607 18.9209C16.1204 20.4095 14.1038 21.2472 12 21.25C9.89625 21.2472 7.87961 20.4095 6.39303 18.9209C4.90645 17.4323 4.07141 15.4146 4.07141 13.3107" stroke="currentColor" strokeWidth="1" strokeMiterlimit="10" strokeLinecap="round"/>
                                            <path d="M7.11928 2.75L6.17085 6.60772C6.08702 6.94965 6.14202 7.31085 6.32371 7.61238C6.5055 7.9139 6.79909 8.13125 7.14066 8.21689L11.009 9.16532" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Resetuj
                                    </button>
                                </div>
                                <TransformComponent>
                                    <img src={machine?.imageUrl} alt={machine?.modelName}/>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </div>
            </div>
        </div>
    );
};