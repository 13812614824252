import React from 'react';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Navbar from "./components/navbar/Navbar";
import Footer from './components/footer/Footer';
import './App.scss';
import {HomePage} from "./pages/HomePage/HomePage";
import Sidebar from "./components/sidebar/Sidebar";
import {Contact} from "./pages/Contact/Contact";
import {MachinePage} from "./pages/MachinePage/MachinePage";
import MachineContext from "./utils/MachineContext";
import {Machine} from "./models/Machine";
import {MachineOverview} from "./pages/MachineOverview/MachineOverview";
import {MachineComponent} from "./pages/MachineComponent/MachineComponent";
import {ComponentParts} from "./pages/ComponentParts/ComponentParts";
import {ListPage} from "./pages/ListPage/ListPage";
import {ProceedList} from "./pages/ProceedList/ProceedList";
import logo from "./assets/wobis_male_2021-300x64.webp";

function App() {

    const [machine, setMachine] = React.useState<Machine | null>(null);

    return (
        <MachineContext.Provider value={{ machine, setMachine }}>
            <Router>
                <div className="App">
                    <div id={"info-banner"}>Prosimy o przesyłanie list części wyłącznie poprzez formularz</div>
                    <div className="app-wrapper">
                        <Sidebar/>
                        <div id={"wobis-content"}>
                            <Navbar/>
                            <div id="wobis-main-content">
                                <Routes>
                                    <Route path={"/"} element={<HomePage/>}/>
                                    <Route path={"/type/:type"} element={<HomePage/>}/>
                                    <Route path={"/kontakt"} element={<Contact/>}/>
                                    <Route path={"/lista"} element={<ListPage/>}/>
                                    <Route path={"/przeslij-liste"} element={<ProceedList/>}/>
                                    <Route path="/maszyna/:id" element={<MachinePage/>}>
                                        <Route index element={<MachineOverview/>}/>
                                        <Route path="komponent/:componentId" element={<MachineComponent/>}/>
                                        <Route path={"/maszyna/:id/komponent/:componentId/czesci-komponentu/:figId"} element={<ComponentParts/>}/>
                                    </Route>
                                    <Route path={"*"} element={<h1>Strona nie została znaleziona</h1>}/>
                                </Routes>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                <div id="mobile-info">
                    <img src={logo} alt="logo"/>
                    <p>Witamy w aplikacji</p>
                    <p><strong>Katalog części KUBOTA</strong></p>
                    <p>Wersja mobilna w przygotowaniu</p>
                    <p>Zapraszamy do skorzystania z aplikacji na komputerze.</p>
                    <div className="info-ctas">
                        <div className="info-cta">
                            <Link to={"https://minikoparki.pro"} className={"cta-primary"}>Minikoparki.pro</Link>
                        </div>
                        <div className="info-cta">
                            <Link to={"https://wobis.pl"} className={"cta-primary"}>Wobis.pl</Link>
                        </div>
                        <div className="info-cta">
                            <Link to={"https://sklepwobis.pl"} className={"cta-primary"}>Sklepwobis.pl</Link>
                        </div>
                    </div>
                </div>
            </Router>
        </MachineContext.Provider>
    );
}

export default App;
