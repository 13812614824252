import React from "react";
import {Machine} from "../models/Machine";

export interface MachineContextType {
    machine: Machine | null;
    setMachine: React.Dispatch<React.SetStateAction<Machine | null>>;
}

const MachineContext = React.createContext<MachineContextType>({
    machine: null,
    setMachine: () => {}
});

export default MachineContext;