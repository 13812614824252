import React from "react";
import './Footer.scss';

const Footer: React.FC = () => {
  return (
      <footer id="wobis-footer">
        <div className="container">
            <p className={"footer-info"}>&copy; 2024 - WOBIS Maszyny Budowlane Wojtyczok Sp. K.</p>
        </div>
      </footer>
  );
};

export default Footer;