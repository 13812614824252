import {useEffect, useState} from "react";
import axios, {AxiosRequestConfig} from "axios";

function useFetch<T>(url: string, config?: AxiosRequestConfig){

    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        setLoading(true)
        axios.get<T>(url, config)
            .then((response) => {
                setData(response.data);
            }).catch((err) => {
                setError(err);
        }).finally(() => {
           setLoading(false);
        });
    }, [url, config]);

    return {data, loading, error};

}

export default useFetch;