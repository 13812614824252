import axios, {AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";

export function usePost<T>(url: string, config?: AxiosRequestConfig) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const sendPostRequest = async (dataToSend: any) => {
        setLoading(true);
        try {
            const response = await axios.post<T>(url, dataToSend, config);
            setData(response.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, sendPostRequest };
}
