import * as Dialog from '@radix-ui/react-dialog';
import {useShoppingCart} from "../../utils/ListContext";
import './ClearCartButton.scss';

const ClearCartButton = () => {
    const { clearCart } = useShoppingCart(); // Używamy nowej funkcji z kontekstu

    const handleClearCart = () => {
        clearCart();
    };


    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <button className="clear-cart-button">Wyczyść listę</button>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="dialog-overlay"/>
                <Dialog.Content className="dialog-content">
                    <Dialog.Title className={"dialog-title"}>Czy na pewno chcesz wyczyścić listę?</Dialog.Title>
                    <Dialog.Description className="dialog-description">
                        Ta akcja jest nieodwracalna.
                    </Dialog.Description>
                    <div>
                        <Dialog.Close asChild>
                            <button className="clear-cart-confirm" onClick={handleClearCart}>Wyczyść</button>
                        </Dialog.Close>
                        <Dialog.Close asChild>
                            <button className="clear-cart-cancel">Anuluj</button>
                        </Dialog.Close>
                    </div>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default ClearCartButton;